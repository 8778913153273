import React, { PureComponent }  from 'react';
import { Grid } from '@material-ui/core';
import GraphCard from '../../cards/cardsTemplates/graphCard.js'

// GRAPH TOOLS
import { ComposedChart, Tooltip, Legend, BarChart, Bar, Line, XAxis, YAxis, linearGradient } from 'recharts';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// Helper props and functions
import fetchData from '../../functions/fetch_data.js';
import format_perc_100base from '../../functions/format_perc_100base.js';
import format_perc_100base_1dp from '../../functions/format_perc_100base_1dp.js';
import MouseOverPopover from '../../buttons/popover.js';

// STYLES
import styles from '../../../styles/main/main_classes.module.css';

const default_bucket = "Industry";

function format_names(data_name) {
    switch (data_name) {
        case "Total EU":
            return "Workforce from EU";
        case "Total Non-EU":
            return "Workforce from Non-EU";
        case "Vacancy Percentage YoY Raw Change":
            return "Change in Vacancy Rate";
        default:
            return data_name
    }
}

class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;
  
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} fontSize='calc(6.5px + 0.35vw)' textAnchor="end" fill="#666" transform="rotate(-45)">
            {payload.value}
          </text>
        </g>
      );
    }
  }

var tooltip
const CustomTooltip = ({ active, payload }) => {
    if (!active || !tooltip)    return null
    for (const bar of payload)
        if (bar.dataKey === tooltip)
            return <div>{ bar.name }<br/>{ bar.value.toFixed(2) }</div>
    return null
}

const SkillsImmigrationGraph = (props) => {

    const Graph = (
            <ComposedChart data={props.data}>
                <Legend 
                    layout="horizontal" 
                    verticalAlign="top" 
                    align="center"
                    wrapperStyle={{marginTop:'-12px'}}> </Legend>
                <XAxis 
                    dataKey="Industry Shortened Name" interval={0}
                    tick={<CustomizedAxisTick/>}
                    minTickGap={0}
                    height={130}/>
                <YAxis 
                    yAxisId="left"
                    tickFormatter={format_perc_100base}
                    domain={[0, 30]}
                    tickCount={4}
                    width={50}
                    tick={{fontSize: 'calc(12px + 0.35vw)'}}
                    />
                <YAxis 
                    yAxisId="right"
                    dataKey="Vacancy Percentage YoY Raw Change"
                    orientation="right"
                    tickFormatter={format_perc_100base}
                    domain={[0, 2]}
                    tickCount={3}
                    width={40}
                    tick={{fontSize: 'calc(12px + 0.35vw)'}}
                    />
                <Bar type="monotone" onMouseOver={ () => tooltip="Total EU" } dataKey="Total EU" name={format_names("Total EU")} yAxisId="left" stackId="b" fill="#083f80"/>
                <Bar type="monotone"  onMouseOver={ () => tooltip="Total Non-EU" } dataKey="Total Non-EU" name={format_names("Total Non-EU")} yAxisId="left" stackId="b" fill="#ED7D31"/>
                <Line type="monotone"  onMouseOver={ () => tooltip="Vacancy Percentage" } dataKey="Vacancy Percentage YoY Raw Change" name={format_names("Vacancy Percentage YoY Raw Change")} yAxisId="right" strokeWidth="0" legendType="circle" stroke="black" dot={{ stroke:"black", strokeWidth: '5', fill:"black"}}stackId="a"/>
                <Tooltip
                    formatter={format_perc_100base_1dp}
                    active={false} />
            </ComposedChart>
    )

    return(
        <Grid 
            container>
            <Grid item xs className={styles.graph_containers}>
                <GraphCard
                    title={"Industry-specific Labour Demand vs. EU Immigration"}
                    subtitle={"2021"}
                    description={<span>This chart shows the % of workers coming from the EU and elsewhere for each industry in the stacked columns whilst the dots show the increase in <MouseOverPopover visibleText="job vacancy rate" invisibleText="Proportion of total posts, which the employer intends to fill within a specific time period, that are vacant"></MouseOverPopover> compared to the previous year.</span>}
                    source={"ONS Vacancy Survey & Annual Population Survey"}
                    graph={Graph}
                    addons={props.toggle}>
                </GraphCard>
            </Grid>
    </Grid>
    )
}

function ToggleFunction(props) {

    return (
        <ToggleButtonGroup 
            value={props.value} 
            size="small"
            exclusive onChange={props.switch}>
            {props.buckets.map((bucket) =>
                <ToggleButton value={bucket}><div>{bucket}</div></ToggleButton>
            )}
        </ToggleButtonGroup>
    )

}


class SkillsvImmigration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data_graph: null,
            data_date: null,
            bucket: default_bucket

        };
    }


    async componentDidMount() {
        const graph_dataset = await fetchData("pi_labourdemand_bar");
        this.setState({
            data_graph: graph_dataset,
            isLoading: false,
            industry_buckets:Object.keys(graph_dataset)
        });
    }
    
    handleSwitchBucket = (event, newValue) => {
        if (newValue !== null) {
            this.setState({ bucket: newValue });
        }
    }


    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }

        const toggle = <ToggleFunction 
            value={this.state.bucket}
            switch={this.handleSwitchBucket}
            buckets={this.state.industry_buckets}></ToggleFunction>;

        return (
            <div>       
                <SkillsImmigrationGraph
                    data={this.state.data_graph[this.state.bucket]}
                    toggle={toggle} >
                </SkillsImmigrationGraph>
            </div>
        )
        }
    }

export default SkillsvImmigration;