import React, { PureComponent }  from 'react';
import EobTable from '../cards/eob_table.js'

// STYLES
import styles from '../../styles/main/main_classes.module.css';

export default class EoBSubsection extends PureComponent {
  
  render() {
    return (
        <div> 
            <h2 className={styles.titles_with_blue_line}>Ease of Doing Business</h2>
            <h3>
            Our <span style={{fontWeight:"bold"}}>bi-annual pulse survey of members</span> allows us to track how regulatory changes are affecting companies in the UK trading with France and the EU.
            </h3>
            <h3>
            The latest survey illustrates the difficulties faced by companies at the border. <span style={{fontWeight:"bold"}}> Delays are increasing and have become a permanent feature </span> of the trading relationship when importing from the EU. As a consequence, the volumes of exported goods have markedly dropped, and logistic costs have increased.
            </h3>  
            <EobTable></EobTable> 
        </div>
        
    );
  }
}