import React from 'react';
import Grid from '@material-ui/core/Grid';

import EkiButton from '../buttons/ekibutton.js';
import CCFGBLogo from '../images/ccfgb_logo-inverted.svg';

// STYLES
import styles from '../../styles/main/main_classes.module.css';

const NavBar = () => {

    return (
        <div>
            <Grid 
                direction="row" 
                container
                className={styles.navbar}>
                <Grid
                    item xs={3} 
                    className={styles.logo_ccfgb}>
                        <a href="https://www.ccfgb.co.uk/" target="_blank" rel="noreferrer">
                            <img src={CCFGBLogo} alt="CCFGB-Logo" height="100px" style={{height:"120px", overflow:"hidden", margin: "-160px 0px -55px 0px"}}/>
                        </a>
                </Grid>
                <Grid 
                    item xs = {12} sm={6}
                    className={styles.navbar_title}>
                    <h1>The UK-France Business Dashboard</h1>
                </Grid>
                <Grid 
                    item xs={3}
                    className={styles.eki_button}>
                    <EkiButton></EkiButton>
                </Grid>
            </Grid>
        </div>
    );
}

export default NavBar;