import React from 'react';
import ContextSubsection from '../subsection/pi_context.js'
import WorkSubsection from '../subsection/pi_work.js'
import StudySubsection from '../subsection/pi_study.js'

// STYLES
import styles from '../../styles/main/main_classes.module.css';
import fetchData from '../functions/fetch_data.js';


export default class PeopleAndImmigrationSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: null
    }
  }

  async componentDidMount() {
    const dataset = await fetchData("pi_text");
    this.setState({
        data: dataset['data'],
        isLoading: false
    });
  }
  
  render() {
    if (this.state.isLoading) {
      return <div>loading...</div>
    } 
    
    return (
        <div> 

          <br></br>
          <h2 className={styles.titles_with_red_line}>People & Immigration</h2>
          <h3>
            The Immigration Act, passed into law on 31st December, drew the curtains on a period of unparalleled freedom of movement between the UK and EU.
            The new points-based immigration system is expected to alter the complexion of immigration, affecting the number of those who migrate and their motivation.
            This section aims to highlight the impact of new regulations on flows of economic migrants and international students.
          </h3>
          <br></br>
          <ContextSubsection migratingwork={this.state.data["migrating_for_work"]}></ContextSubsection>
          <br></br>
          <WorkSubsection hires={this.state.data["proportion_EU_hires"]} visas={this.state.data["proportion_increased_work_visas_sponsored"]} reason={this.state.data["reason_change_new_hires"]}>
          </WorkSubsection>
          <br></br>
          <StudySubsection EUStudents={this.state.data["students"]["EU_number"]} EUStudentPerc={this.state.data["students"]["EU_perc"]} EUSciPerc={this.state.data["students"]["EU_sci_perc"]} EUNonSciPerc={this.state.data["students"]["EU_nonsci_perc"]} EUUGPerc={this.state.data["students"]["EU_ug_perc"]} EUPGPerc={this.state.data["students"]["EU_pg_perc"]}>
          </StudySubsection>
          <br></br>

        </div>
    )  
  }
}
