import { Grid } from '@material-ui/core';
import React from 'react';
import GraphCard from '../../cards/cardsTemplates/graphCard.js'

// GRAPH TOOLS
import { Tooltip, LabelList, Legend, BarChart, Bar, Text, XAxis, YAxis } from 'recharts';

// Helper props and functions
import fetchData from '../../functions/fetch_data.js';
import graph_format_numbers_million_dec from '../../functions/graph_format_numbers_million_dec.js';

// STYLES
import styles from '../../../styles/main/main_classes.module.css';


const EmploymentLevelsGraph = (props) => {

    const Graph = (
        <BarChart data={props.data} margin={{ top: 0, left: 10, right: 0, bottom: 0 }} >
            <Legend 
                layout="horizontal" 
                verticalAlign="top" 
                align="center"
                wrapperStyle={{marginTop:'-12px'}}> </Legend>
            <XAxis 
                dataKey="Year" interval={0}
                minTickGap={0}/>
            <Bar type="monotone" dataKey="EU" stackId="a" fill ="#083f80">
                <LabelList dataKey="EU" position="inside"  style={{fill:"white"}} formatter={graph_format_numbers_million_dec}/>
            </Bar>
            <Bar type="monotone" dataKey="Non-EU"  stackId="a" fill = "#ED7D31">
                <LabelList dataKey="Non-EU" position="inside" style={{fill:"white"}} formatter={graph_format_numbers_million_dec}/>
            </Bar>
            <Tooltip
                formatter={graph_format_numbers_million_dec}
                cursor={<rect fillOpacity="0.3"/>}
                active={false} />
        </BarChart>
        )

    return(
        <Grid 
            container>
            <Grid item xs className={styles.graph_containers}>
                <GraphCard
                    title={"UK Levels of Employment by Nationality"}
                    source={"ONS"}
                    graph={Graph}>
                </GraphCard>
                
            </Grid>
    </Grid>
    )
}


class EmploymentLevels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data_graph: null,

        };
    }


    async componentDidMount() {
        const graph_dataset = await fetchData("pi_employment_bar");
        this.setState({
            data_graph: graph_dataset,
            isLoading: false,
        });
    }
    

    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }

        return (
            <div>       
                <EmploymentLevelsGraph
                    data={this.state.data_graph['data']}>
                </EmploymentLevelsGraph>
            </div>
        )
        }
    }

export default EmploymentLevels;