function format_perc_change(value, decimal_point) {
    if (value === "-") {
        return value
    } else {
        const sign = (value >= 0) ? "+" : ""; // negative sign added automatically
        return sign + Number(value).toFixed(decimal_point) + "%" 
    }
    

}

export default format_perc_change;
