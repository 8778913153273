import React, { PureComponent }  from 'react';
import Grid from '@material-ui/core/Grid';

// Components
import StudentCountries from '../charts/customCharts/studentsenderCountries.js'
import CoursesChoice from '../charts/customCharts/unicoursesChoice.js'
import StudylevelDistribution from '../charts/customCharts/studylevelDistribution.js'

// STYLES
import styles from '../../styles/main/main_classes.module.css';
import graph_format_numbers_no_space from '../functions/graph_format_numbers_no_space.js';
import format_perc from '../functions/format_perc_1dp';



export default class StudySubsection extends PureComponent {
  
  render() {
    return (
        <div> 

          <h2 className={styles.titles_with_blue_line}>Study</h2>
          
          <Grid 
            container
            direction="row"
            style={{ gap : 20 }}>
              <Grid container xs={12} sm
                direction="row"
                style={{textAlign:"justify"}}>
                  <ul>
                      <li style={{marginBottom:"15px"}}>In the 2020/21 academic year there were <span style={{fontWeight:"bold"}}>{graph_format_numbers_no_space(this.props.EUStudents)} students from the EU</span>, representing {format_perc(this.props.EUStudentPerc / 100, 1)} of all students.</li>
                      <li style={{marginBottom:"15px"}}>As the UK opts out of the Erasmus scheme, replacing it with its own Turing scheme, we expect this number to fall. This is due to expectations that the successor to the Erasmus scheme will <span style={{fontWeight:"bold"}}>not provide funding for inbound students.</span></li>
                      <li>In particular, we expect the impact to be largest for postgraduate degrees, where {format_perc(this.props.EUPGPerc / 100, 1)} of students are from the EU (compared to {format_perc(this.props.EUUGPerc / 100, 1)} for undergraduate studies).</li>
                  </ul>
                </Grid>
                <Grid xs={12} sm>
                  <StudentCountries></StudentCountries>
                </Grid>
          </Grid>
          <br></br>
          <Grid 
            container
            direction="column"
            style={{ gap : 20 }}>
              <Grid item xs><CoursesChoice></CoursesChoice></Grid>
              <Grid item xs><StudylevelDistribution></StudylevelDistribution></Grid>
              
          </Grid>
             
        </div>
    );
  }
}