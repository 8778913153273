import { Grid } from '@material-ui/core';
import React from 'react';
import GraphCard from '../../cards/cardsTemplates/graphCard.js'

// GRAPH TOOLS
import { Tooltip, Legend, BarChart, Bar, XAxis, YAxis, linearGradient } from 'recharts';

// Helper props and functions
import fetchData from '../../functions/fetch_data.js';
import format_perc_100base from '../../functions/format_perc_100base.js';

// STYLES
import styles from '../../../styles/main/main_classes.module.css';

const ReasonsImmigrationGraph = (props) => {

    const Graph = (
        <BarChart data={props.data} layout="vertical">
             <Legend 
                layout="horizontal" 
                verticalAlign="top" 
                align="center"
                wrapperStyle={{marginTop:'-25px'}}> </Legend>
            <XAxis type="number" tickCount={3} tickFormatter={format_perc_100base} tickLine={false} axisLine={false} domain={[0, 50]} padding={{right:5}}/>
            <YAxis type="category" width={120} tickLine={false} dataKey="Reason" tick={{fontSize: 'calc(10px + 0.35vw)'}}
                />
            <Bar type="monotone" dataKey="EU" fill = "#083f80"/>
            <Bar type="monotone" dataKey="Non-EU" fill = "#ED7D31"/>
            <Tooltip
                cursor={<rect fillOpacity="0.3"/>}
                formatter={format_perc_100base} 
                active={false} />
        </BarChart>
    )

    return(
        <Grid 
            container>
            <Grid item xs className={styles.graph_containers}>
                <GraphCard
                    title={"Reason for Immigration to the UK by country of residence"}
                    source={"Labour Force Survey"}
                    graph={Graph}>
                </GraphCard>
                
            </Grid>
    </Grid>
    )
}

class ReasonsImmigration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data_graph: null,
            years: null,

        };
    }

    async componentDidMount() {
        const graph_dataset = await fetchData("pi_immigrationreason_bar");
        this.setState({
            data_graph: graph_dataset,
            isLoading: false,
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }
        
        return (
            <div>
                <ReasonsImmigrationGraph
                    data={this.state.data_graph['Immigration reason']}></ReasonsImmigrationGraph>
            </div>
        )
        }
    }

export default ReasonsImmigration;