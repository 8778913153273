async function fetchData(endpoint) {
    // Quick check to ensure that we are not in local build
    const url = (process.env.REACT_APP_ENV === "local") ?  process.env.REACT_APP_BACKEND_URL + "api/v1/" + endpoint : "api/v1/" + endpoint;
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
    });
    return response.json()
}

export default fetchData;