import React, { PureComponent }  from 'react';
import FdiChart from '../charts/customCharts/fdiChart.js'

// STYLES
import styles from '../../styles/main/main_classes.module.css';
import MouseOverPopover from '../buttons/popover.js';


export default class FDISubsection extends PureComponent {
  
  render() {
    return (
        <div> 
            <h2 className={styles.titles_with_blue_line}>FDI</h2>
            <h3>
              Historically, around <span style={{fontWeight:"bold"}}>half of foreign investment into the UK has come from the EU</span>, with London the pre-eminent European destination for international investment.
              However, since the EU Referendum result we have seen <MouseOverPopover visibleText="the UK's position decline with France surpassing it as the largest attractor" invisibleText="EY 2020 UK Attractiveness Survey" text="bold"></MouseOverPopover> for external investment for the first time in 2019. 
              Whether stricter controls on immigration and data sharing with EU entities opens the door for Non-EU partners, or diminishes the net inflow of investment into the UK, will be seen in the coming years.
            </h3>
            <FdiChart></FdiChart>
        </div>
    );
  }
}