import React from 'react';
import Grid from '@material-ui/core/Grid';

// STYLES
import styles from '../../styles/main/main_classes.module.css';

// Helper functions
import fetchData from '../functions/fetch_data.js';

class IntroSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            updateDate: null
        };
    }    

    async componentDidMount() {
        const updateDate = await fetchData("update_timestamp");
        this.setState({
            updateDate: updateDate['date'],
            isLoading: false,
        });
    }    

    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }

        return (
            <div> 
                <h1>
                    Welcome to the UK-France Business Dashboard, an initiative led by the French Chamber of Great Britain (CCFGB).
                </h1>
                <p className={styles.last_updated_date}>Last Updated on: {this.state.updateDate}</p>
                <br></br>
                <Grid
                    container
                    direction="column"
                    style={{gap:20}}>
                    <Grid 
                        container 
                        direction="row" 
                        justify="center"
                        style={{ gap: 20 }}>
                        <Grid
                            item xs
                            className={styles.intro_boxes_left}>
                            <span className={styles.titles_with_red_line_in_boxes}>The CCFGB</span>
                            <br></br>
                            <p className={styles.paragraphs_in_boxes}>The French Chamber of Commerce and Industry in Great Britain has served the French and British business communities and strengthened relationships since 1883. Together our members are responsible for significant trade and investment in the UK. France is the UK’s second-largest trading partner in Europe, fifth-largest export market for goods in the world, and major global source of imported goods and services. Investment by French companies in the UK is consequential and mirrored by similar levels of UK investment in France.
                            </p>
                        </Grid>
                        <Grid item xs
                            className={styles.intro_boxes_right}>
                            <span className={styles.titles_with_red_line_in_boxes}>The UK-France Business Dashboard</span>
                            <br></br>
                            <p className={styles.paragraphs_in_boxes}>We measure the strength of the economic relationship through our UK-France Dashboard. By combining survey data from our extensive business network with a range of open-source data sets on trade and investment, we are able to offer a unique and up-to-date perspective on trading conditions.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid
                        container>
                        <Grid
                            item xs
                            className={styles.intro_boxes_bottom}>
                            <span className={styles.titles_with_blue_line_in_box}>Ekimetrics</span>
                            <br></br>
                            <p className={styles.paragraphs_in_boxes}>Ekimetrics is a <span style={{fontWeight:"bold"}}>European leader in delivering data science for business</span> to clients around the globe. Ekimetrics supports its customers to maximise their marketing and operation performance through increasing 
                            their data capital and deploying action-orientated solutions. With a focus on capturing short-term value on the path to long-term capabilities, it has delivered more than <span style={{fontWeight:"bold"}}>1,000 data science projects</span> in over <span style={{fontWeight:"bold"}}>50 countries</span>, generating more than <span style={{fontWeight:"bold"}}>€1Bn</span> in profits for its customers.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default IntroSection;
