import React from 'react';
import Grid from '@material-ui/core/Grid';
import {ResponsiveContainer} from 'recharts';

// STYLES
// import gt_styles from '../../../styles/main/goods_and_trade.module.css';
import styles from '../../../styles/main/main_classes.module.css';

const GraphCardTiny = (props) => {

    return (
        <div>
            <Grid
                container
                direction="column"
                style={{display:"flex", alignItems:"center"}}
                className={styles.graph_containers_tiny}>
                <div>
                    {props.addons}
                    {props.second_addons}
                </div>
                <br></br>
                <ResponsiveContainer width='100%' height='100%'>
                    {props.graph}
                </ResponsiveContainer>
            </Grid>

        </div>

    );
}

export default GraphCardTiny;