function graph_format_currency_abs(value) {
    if (Math.abs(Number(value)) >= 1.0e9) {
        return "£" +(Math.abs(Number(value)) / 1.0e9).toFixed(0) + " bn"
    } else if (Math.abs(Number(value)) >= 1.0e6) {
        return "£" +(Math.abs(Number(value)) / 1.0e6).toFixed(0) + " m"
    } else if (Math.abs(Number(value)) >= 1.0e3) {
        return "£" +(Math.abs(Number(value)) / 1.0e3).toFixed(0) + " k"
    } else {
        return "£" + Math.abs(Number(value))
    }
}

export default graph_format_currency_abs;
