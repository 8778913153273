import { Grid } from '@material-ui/core';
import React from 'react';
import GraphCard from '../../cards/cardsTemplates/graphCard.js'

// GRAPH TOOLS
import { Tooltip, Legend, PieChart, Sector, Cell, Pie, Label, LabelList,  XAxis, YAxis, linearGradient } from 'recharts';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// Helper props and functions
import fetchData from '../../functions/fetch_data.js';
import format_perc from '../../functions/format_perc.js';

// STYLES
import styles from '../../../styles/main/main_classes.module.css';

const COLORS = ['#C00000', '#F8CBAD', 'grey', 'lightgrey'];
const LABELS = ['Decreased', 'Increased', 'No change', 'No new hires'];

function format_bucket_name(bucket){

    if (bucket === "Interns") {
    
    return "Interns/V.I.E"
    
    } else {
    
    return bucket
    
    }
    
    }

const default_bucket = "New Hires";

const WorkforceEvolutionGraph = (props) => {

    const Graph = (
        <PieChart 
            margin={{ top: 5, right: 5, left: 10 }}
            dataKey="value">
            <Pie
                data={props.data}
                cx="50%"
                cy="50%"    
                dataKey="value"
                nameKey="response"
                paddingAngle={5}
                innerRadius={"50%"}
                labelLine={false}
                outerRadius={"100%"}>
                    {props.data.map((entry, index) => (
                        <Cell fill={COLORS[index % COLORS.length]} style={{fontSize: "calc(8px + 0.35vw)"}} />
                    ))}
                    <LabelList dataKey="value" fill="black" stroke="black" formatter={format_perc} style={{fontSize: "calc(10px + 0.35vw)"}} >
                    </LabelList>
            </Pie>
                <Legend />
        </PieChart>
    )

    return(
        <Grid 
            container style={{alignContent:"center", justifyContent:"center"}}>
            <Grid item xs className={styles.graph_containers}>
                <GraphCard
                    title={"Evolution of Company Workforce Localisation"}
                    subtitle={"UK vs. EU"}
                    description={<span>Many of our members <b>struggle to secure and retain the talent</b> needed for business growth. Our results show that the impact of Brexit continues to exasperate an already tight labour market in the UK. 
                        The increased costs of recruitment from the EU are limiting UK companies to a smaller talent pool and blocking opportunities for youth mobility. VIEs are no longer a viable route to bring young talent to the UK. 
                        </span>}
                    source={"CCFGB Survey"}
                    graph={Graph}
                    addons={props.toggle}>
                </GraphCard>
                
            </Grid>
    </Grid>
    )
}

function ToggleFunction(props) {

    return (
        <ToggleButtonGroup 
            value={props.value} 
            size="small"
            exclusive onChange={props.switch}>
            {props.buckets.map((bucket) =>
                <ToggleButton value={bucket}><div>{format_bucket_name(bucket)}</div></ToggleButton>
            )}
        </ToggleButtonGroup>
    )

}

class WorkforceEvolution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data_graph: null,
            data_date: null,
            bucket: default_bucket

        };
    }


    async componentDidMount() {
        const graph_dataset = await fetchData("pi_headcount_pie");
        this.setState({
            data_graph: graph_dataset,
            isLoading: false,
            hiring_buckets:Object.keys(graph_dataset)
        });
    }
    
    handleSwitchBucket = (event, newValue) => {
        if (newValue !== null) {
            this.setState({ bucket: newValue });
        }
    }

    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }

        const toggle = <ToggleFunction 
            value={this.state.bucket}
            switch={this.handleSwitchBucket}
            buckets={this.state.hiring_buckets}></ToggleFunction>;


        return (
            <div>       
                <WorkforceEvolutionGraph
                    data={this.state.data_graph[this.state.bucket]}
                    toggle={toggle} >
                </WorkforceEvolutionGraph>
            </div>
        )
        }
    }

export default WorkforceEvolution;