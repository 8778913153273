import { Grid } from '@material-ui/core';
import React from 'react';
import GraphCard from '../../cards/cardsTemplates/graphCard.js'

// GRAPH TOOLS
import { Tooltip, Legend, AreaChart, Line, Area, XAxis, YAxis, linearGradient } from 'recharts';

// HELPER FUNCTIONS
// import format_currency from '../../functions/format_currency.js';
import graph_format_currency from '../../functions/graph_format_currency.js';

// STYLES
import styles from '../../../styles/main/main_classes.module.css';

function year_only(value) {
    return (value.substring(0,5))
}

const TradeFlowsGraph = (props) => {

    const Graph = (
        <AreaChart data={props.data}>
            <Legend wrapperStyle={{marginTop:'-30px'}} layout="horizontal" verticalAlign="top" align="center"> </Legend>
            <XAxis dataKey="Date" interval={7} height={20} tick={{fontSize: 'calc(10px + 0.35vw)'}} tickFormatter={year_only} padding={{right:10}}/>
            <YAxis 
                width={60}
                tickFormatter={graph_format_currency}
                tick={{fontSize: 'calc(9px + 0.4vw)'}}
                />
            <Area type="monotone" dataKey="Exports"  stroke="#118046" strokeWidth={2} fill="#FFFFFF"/>
            <Area type="monotone" dataKey="Imports"  stroke="#a12b35" strokeWidth={2} fill="#FFFFFF"/>
            <Tooltip
                formatter={graph_format_currency}
                active={false} />
        </AreaChart>
    )

    return(
        <Grid 
            container>
            <Grid item xs className={styles.graph_containers}>
                <GraphCard
                    title={props.region + " Trade Balance Volume - " + props.industry}
                    subtitle={"2010 - Today"}
                    description={"This chart shows the value of UK exports and imports by industry over time, for the EU and rest of the world."}
                    source={"ONS"}
                    graph={Graph}>
                </GraphCard>
                
            </Grid>
    </Grid>
    )
}

export default TradeFlowsGraph;