import React, { PureComponent }  from 'react';
import { Grid } from '@material-ui/core';
import GraphCard from '../../cards/cardsTemplates/graphCard.js'

// GRAPH TOOLS
import { Tooltip, Legend, BarChart, Bar, Area, XAxis, YAxis, linearGradient } from 'recharts';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// Helper props and functions
import fetchData from '../../functions/fetch_data.js';
import format_perc from '../../functions/format_perc.js';

// STYLES
import styles from '../../../styles/main/main_classes.module.css';

const default_year = "2020/21";

class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;
  
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} fontSize='calc(6px + 0.3vw)' textAnchor="end" fill="#666" transform="rotate(-45)">
            {payload.value}
          </text>
        </g>
      );
    }
  }

const DegreeTypesGraph = (props) => {

    const Graph = (
        <BarChart data={props.data}>
            <Legend 
                layout="horizontal" 
                    verticalAlign="top" 
                    align="center"
                    wrapperStyle={{marginTop:'-12px'}}> </Legend>
            <XAxis dataKey="Level of Study" interval={0} 
                tick={<CustomizedAxisTick/>} angle={-45} height={150}/>
            <YAxis 
                width={50}
                tickFormatter={format_perc}
                tick={{fontSize: 'calc(10px + 0.35vw)'}}
                />
            <Bar type="monotone" dataKey="EU" stackId="a" fill = "#083f80"/>
            <Bar type="monotone" dataKey="Non-EU"  stackId="a" fill = "#86a1c2"/>
            <Bar type="monotone" dataKey="UK" stackId="a" fill="#dadee3"/>
            <Tooltip
                formatter={format_perc}
                cursor={<rect fillOpacity="0.3"/>}
                active={false} />
        </BarChart>
    )

    return(
        <Grid 
            container>
            <Grid item xs className={styles.graph_containers}>
                <GraphCard
                    title={"Distribution of EU and non-EU Students by levels of study"}
                    subtitle={"By Academic Year (from 2014/15)"}
                    // description={"x"}
                    source={"HESA"}
                    graph={Graph}
                    addons={props.dropdown}>
                </GraphCard>
                
            </Grid>
    </Grid>
    )
}

function DropDownFunction(props) {

    return (
        <Autocomplete
            options={props.years}
            getOptionLabel={(option) => option}
            size="small"
            renderInput={(params) => <TextField {...params} label = 'Year' variant="outlined"/>} 
            onChange={props.switchfunction}
            defaultValue={props.year}
            style={{fullWidth:true, width:"150px"}}
        />
    )

}

class CoursesChoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data_graph: null,
            data_date: null,
            year: default_year,
            years: null,

        };
    }


    async componentDidMount() {
        const graph_dataset = await fetchData("pi_degreetypes_bar");
        this.setState({
            data_graph: graph_dataset,
            isLoading: false,
            data_date: null,
            years:Object.keys(graph_dataset),
        });
    }
    

    handleSwitchYear = (event, newValue) => {
        if (newValue !== null) {
            this.setState({ year: newValue });
        }
    }

    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }
        
        const dropdown = <DropDownFunction
            years={this.state.years}
            switchfunction={this.handleSwitchYear}
            year={this.state.year}></DropDownFunction>;

        return (
            <div>
                <DegreeTypesGraph
                    data={this.state.data_graph[this.state.year]}
                    dropdown={dropdown}></DegreeTypesGraph>
            </div>
        )
        }
    }

export default CoursesChoice;