import { Grid } from '@material-ui/core';
import React from 'react';
import GraphCard from '../../cards/cardsTemplates/graphCard.js'

// GRAPH TOOLS
import { Tooltip, LabelList, Legend, BarChart, Bar, XAxis, YAxis } from 'recharts';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// Helper props and functions
import fetchData from '../../functions/fetch_data.js';
import graph_format_currency from '../../functions/graph_format_currency.js';
import graph_format_currency_abs from '../../functions/graph_format_currency_abs.js';


// STYLES
import styles from '../../../styles/main/main_classes.module.css';

const default_industry_nonEU = "All Industries";
const default_industry_EU = "All Industries";
const default_region_nonEU = "Non-EU Total";
const default_region_EU = "EU Total";

function format_country(country) {
    return (country === "EU Total" || country === "Non-EU Total") ? "Total" : country;
}

const EUFlowsGraph = (props) => {

    const EUGraph = (

        <BarChart data={props.data_EU}>   
        <Legend 
            layout="horizontal" 
            verticalAlign="top" 
            align="center"
            wrapperStyle={{marginTop:'-25px'}}> </Legend>
            <XAxis 
                dataKey="Year" 
                interval={0}
                minTickGap={0}
                height={20}
                tick={{fontSize: 'calc(8px + 0.5vw)'}}
                axisLine={false}
                tickLine={false}/>
            <XAxis 
                dataKey="Year"
                xAxisId={1} 
                interval={0}
                minTickGap={0}
                hide/>
            <Tooltip
                formatter={graph_format_currency}
                cursor={<rect fillOpacity="0.2"/>}
                active={false} />
            <Bar type="monotone" dataKey="Inbound" xAxisId={1} fillOpacity="0.5"fill ="#7d7d7d">
                <LabelList dataKey="Inbound" position="inside"  formatter={graph_format_currency_abs} style={{ fontSize:"calc(6px + 0.6vw)" }}/>
            </Bar>
            <Bar type="monotone" dataKey="Outbound" xAxisId={0} fillOpacity="0.5" fill ="#cfcfcf">
                <LabelList dataKey="Outbound" position="inside"  formatter={graph_format_currency_abs}  style={{ fontSize:"calc(6px + 0.6vw)" }}/>
            </Bar>
        </BarChart>
    )

    return(
        <Grid 
            container>
            <Grid item xs className={styles.graph_containers_fdi}>
                <GraphCard
                    source={"IGNORE"}
                    graph={EUGraph}>
                </GraphCard>
                
            </Grid>
    </Grid>
    )
}


const NonEUFlowsGraph = (props) => {

    const NonEUGraph = (

        <BarChart data={props.data_nonEU}>
        <Legend 
            layout="horizontal" 
            verticalAlign="top" 
            align="center"
            wrapperStyle={{marginTop:'-25px'}}> </Legend>
            <XAxis 
                dataKey="Year" 
                xAxisId={0}
                interval={0}
                minTickGap={0}
                height={20}
                axisLine={false}
                tick={{fontSize: 'calc(8px + 0.5vw)'}}
                tickLine={false}/>
            <XAxis 
                dataKey="Year"
                xAxisId={1} 
                interval={0}
                minTickGap={0}
                hide/>
            <Bar type="monotone" dataKey="Inbound" xAxisId={1} fillOpacity="0.5"fill ="#7d7d7d">
                <LabelList dataKey="Inbound" position="inside"  formatter={graph_format_currency_abs}  style={{ fontSize:"calc(6px + 0.6vw)" }}/>
            </Bar>
            <Bar type="monotone" dataKey="Outbound" xAxisId={0} fillOpacity="0.5" fill ="#cfcfcf">
                <LabelList dataKey="Outbound" position="inside"  formatter={graph_format_currency_abs}  style={{ fontSize:"calc(6px + 0.6vw)" }}/>
            </Bar>
            <Tooltip
                formatter={graph_format_currency}
                cursor={<rect fillOpacity="0.2"/>}
                active={false} />
        </BarChart>
    )

    return(
        <Grid 
            container>
            <Grid item xs className={styles.graph_containers_fdi}>
                <GraphCard
                    source={"ONS"}
                    graph={NonEUGraph}>
                </GraphCard>
            </Grid>
    </Grid>
    )
}

class FdiFlows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            graph_dataset:null,
            region_EU: default_region_EU,
            region_nonEU: default_region_nonEU,
            industry_EU: default_industry_EU,
            industry_nonEU: default_industry_nonEU,
        };
    }
    
    async componentDidMount() {
        const data = await fetchData("gt_fdi_bar");
        this.setState({
            isLoading: false,
            graph_dataset:data,
            industries: Object.keys(data),
            EU_countries: Object.keys(data[default_industry_EU]['EU']),
            nonEU_countries: Object.keys(data[default_industry_EU]['Non-EU']),
        });
    }
    
    handleSwitchIndustry = (event, newValue) => {
        if (newValue !== null) {
            this.setState({ 
                industry_EU: newValue,
                industry_nonEU : newValue});
        }
    }

    handleSwitchRegionEU = (event, newValue) => {
        if (newValue !== null) {
            this.setState({ region_EU: newValue });
        }
    }

    handleSwitchRegionNonEU = (event, newValue) => {
        if (newValue !== null) {
            this.setState({ region_nonEU: newValue });
        }
    }

    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }

        return (
            <div>
                <Grid 
                    container
                    className={styles.graph_containers}
                    style={{display:"flex"}}>
                    <Grid 
                        container
                        style={{display:"flex", justifyContent:"center", marginTop:"15px"}}
                        xs={12}>
                        <Autocomplete
                                options={this.state.industries}
                                getOptionLabel={(option) => option}
                                size="small"
                                style={{fullWidth:true, width:"250px"}}
                                renderInput={(params) => <TextField {...params} label = 'Industries' variant="outlined"/>} 
                                onChange={this.handleSwitchIndustry}
                                defaultValue={this.state.industry_EU}/>
                    </Grid>
                    <Grid 
                        container
                        xs={6}
                        direction="column">
                        <div>
                            <h2 style={{display:"flex", fontSize:"calc(10px + 1vw)", justifyContent:"center", alignContent:"center"}}>EU Investment</h2>
                        </div>
                        <Grid
                        style={{display:"flex", justifyContent:"space-around"}}>
                            <Autocomplete
                                options={this.state.EU_countries}
                                getOptionLabel={(option) => format_country(option)}
                                size="small"
                                style={{fullWidth:true, width:"125px"}}
                                renderInput={(params) => <TextField {...params} label = 'Countries' variant="outlined"/>} 
                                onChange={this.handleSwitchRegionEU}
                                defaultValue={this.state.region_EU}
                            />  
                        </Grid>
                        <EUFlowsGraph
                            data_EU={this.state.graph_dataset[this.state.industry_EU]['EU'][this.state.region_EU]}>
                        </EUFlowsGraph>
                    </Grid>                    
                    <Grid 
                        container
                        xs={6}
                        direction="column">
                        <div>
                            <h2 style={{display:"flex", fontSize:"calc(10px + 1vw)", justifyContent:"center"}}>Non-EU Investment</h2>
                        </div>
                        <Grid
                        style={{display:"flex", justifyContent:"space-around"}}>
                            <Autocomplete
                                options={this.state.nonEU_countries}
                                getOptionLabel={(option) => format_country(option)}
                                size="small"
                                style={{fullWidth:true, width:"125px"}}
                                renderInput={(params) => <TextField {...params} label = 'Countries' variant="outlined"/>} 
                                onChange={this.handleSwitchRegionNonEU}
                                defaultValue={this.state.region_nonEU}
                            />        
                        </Grid>
                        <NonEUFlowsGraph
                            data_nonEU={this.state.graph_dataset[this.state.industry_nonEU]['Non-EU'][this.state.region_nonEU]}>
                        </NonEUFlowsGraph>
                    </Grid>
                </Grid>
            </div>
        )
        }
    }

export default FdiFlows;