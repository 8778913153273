import React from 'react';
import Grid from '@material-ui/core/Grid';
import {ResponsiveContainer} from 'recharts';

// STYLES
// import gt_styles from '../../../styles/main/goods_and_trade.module.css';
import styles from '../../../styles/main/main_classes.module.css';

const GraphCard = (props) => {

    return (
        <div>
            <Grid 
                container
                xs={12}
                direction="column"
                className={styles.graph_containers}>
                <div>
                    <h2>{props.title}</h2>
                    <h3>{props.subtitle}</h3>
                    <p style={{fontStyle:"italic"}}>{props.description}</p>
                </div>
                <Grid
                    container
                    direction="column"
                    style={{display:"flex", alignItems:"center", justifyItems:"center"}}
                    className={styles.graph_containers_smaller}>
                    <div>
                        {props.addons}
                        {props.second_addons}
                    </div>
                    <br></br>
                    <ResponsiveContainer width='100%' height={400}>
                        {props.graph}
                    </ResponsiveContainer>
                </Grid>
                <br></br>
                <Grid
                    item xs
                    className={styles.graph_source}>
                    {(props.source==="IGNORE") ? <p></p> : <p>Source: {props.source}</p>}
                </Grid>
            </Grid>
        </div>

    );
}

export default GraphCard;