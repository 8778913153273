import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// STYLES
import gt_styles from '../../styles/main/goods_and_trade.module.css';
import styles from '../../styles/main/main_classes.module.css';

// Helper functions
import fetchData from '../functions/fetch_data.js';
import format_perc from '../functions/format_perc.js';

import DelayDist from '../charts/customCharts/eobDelayDist.js'

const DEFAULT_EDITION = '2021 Q3'
  
class EobTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: null,
            delay_data: null,
            editions: null,
            edition: DEFAULT_EDITION
        };
    }

    async componentDidMount() {
        const data = await fetchData("gt_easeofbusiness_table");
        const delay_data = await fetchData("gt_delaydist_bar");
        
        this.setState({
            data: data,
            delay_data: delay_data,
            editions: Object.keys(data),
            isLoading: false,
        });
    }

    handleSwitchEdition = (event, newValue) => {
        if (newValue !== null) {
            this.setState({edition: newValue });
        }
    }

    render() {
        
        if (this.state.isLoading) {
            return <div>loading...</div>
        }

        return (
            <div>
                <Grid container
                direction="row"
                justifyContent="center"
                style={{ gap: 20,justifyContent:"space-evenly"}}>
                    <Grid item>
                    {/* sm = {12} md ={4} */}
                        <ToggleButtonGroup value={this.state.edition} exclusive onChange={this.handleSwitchEdition}>
                            {this.state.editions.map((edition) =>
                                <ToggleButton value={edition}><div>{edition}</div></ToggleButton>
                            )}
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>

                <TableContainer align="center">
                    <Table style={{width:'95%'}}>                      
                        <colgroup>
                            <col style={{width:'60%'}}/>
                            <col style={{width:'20%'}}/>
                            <col style={{width:'20%'}}/>
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="center" style={{color:'#004079', fontFamily: 'Noto Sans', fontWeight:'bold', fontSize:'calc(16px + 0.35vw)', paddingLeft:'0', paddingRight:'0'}}>For Imports</TableCell>
                                <TableCell align="center" style={{color:'#004079', fontFamily: 'Noto Sans', fontWeight:'bold', fontSize:'calc(16px + 0.35vw)', paddingLeft:'0', paddingRight:'0'}}>For Exports</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow >
                                <TableCell style={{color:'#004079', fontFamily: 'Noto Sans', border: '10px solid white', fontWeight:'bold', fontSize:'calc(12px + 0.5vw)', paddingLeft:'0'}}>Companies declaring they experienced delays…</TableCell>
                                <TableCell  align="center" style={{background:'#004079', border: '10px solid white', color:"white", fontFamily: 'Noto Sans', fontSize:'calc(25px + 0.35vw)', paddingLeft:'0', paddingRight:'0'}}>{format_perc(this.state.data[this.state.edition]["imports"]["experienced_delays"])}</TableCell>
                                <TableCell align="center" style={{background:'#CBD8E3', border: '10px solid white', color:"white", fontFamily: 'Noto Sans', fontSize:'calc(25px + 0.35vw)', paddingLeft:'0', paddingRight:'0'}}>{format_perc(this.state.data[this.state.edition]["exports"]["experienced_delays"])}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right" style={{color:'#004079', fontFamily: 'Noto Sans', border: '10px solid white', fontSize:'calc(12px + 0.5vw)', paddingLeft:'0'}}>With an average delay of…</TableCell>
                                {/* <TableCell align="center" style={{background:'white', color:"#004079", fontFamily: 'Noto Sans', fontSize:'calc(22px + 0.35vw)', paddingLeft:'0', paddingRight:'0'}}>{this.state.data["imports"]["average_delay"]}</TableCell> */}
                                <TableCell align="center" style={{background:'white', paddingLeft:'0', paddingRight:'0'}}><DelayDist data={this.state.delay_data[this.state.edition]["imports"]} color={"#004079"}></DelayDist></TableCell>
                                {/* <TableCell align="center" style={{background:'white', color:"#004079", fontFamily: 'Noto Sans', fontSize:'calc(22px + 0.35vw)', paddingLeft:'0', paddingRight:'0'}}>{this.state.data["exports"]["average_delay"]}</TableCell> */}
                                <TableCell align="center" style={{background:'white', paddingLeft:'0', paddingRight:'0'}}><DelayDist data={this.state.delay_data[this.state.edition]["exports"]} color={"#CBD8E3"}></DelayDist></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{color:'#004079', fontFamily: 'Noto Sans', border: '10px solid white',  fontWeight:'bold',fontSize:'calc(12px + 0.5vw)', justifyItems:"flex-end", paddingLeft:'0'}}>Volume of goods traded with the EU decreased in the last quarter…</TableCell>
                                <TableCell align="center" style={{background:'#004079', color:"white", border: '10px solid white', fontFamily: 'Noto Sans', fontSize:'calc(25px + 0.35vw)', paddingLeft:'0', paddingRight:'0'}}>{format_perc(this.state.data[this.state.edition]["imports"]["decreased_volume"])}</TableCell>
                                <TableCell align="center" style={{background:'#CBD8E3', color:"white", border: '10px solid white', fontFamily: 'Noto Sans', fontSize:'calc(25px + 0.35vw)', paddingLeft:'0', paddingRight:'0'}}>{format_perc(this.state.data[this.state.edition]["exports"]["decreased_volume"])}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{color:'#004079', fontFamily: 'Noto Sans', border: '10px solid white', fontWeight:'bold',fontSize:'calc(12px + 0.5vw)', paddingLeft:'0'}}>Companies declaring their cost of logistics increased…</TableCell>
                                <TableCell align="center" style={{background:'#004079', color:"white", border: '10px solid white', fontFamily: 'Noto Sans', fontSize:'calc(25px + 0.35vw)', paddingLeft:'0', paddingRight:'0'}}>{format_perc(this.state.data[this.state.edition]["imports"]["increased_logistics_cost"])}</TableCell>
                                <TableCell align="center" style={{background:'#CBD8E3', color:"white", border: '10px solid white', fontFamily: 'Noto Sans', fontSize:'calc(25px + 0.35vw)', paddingLeft:'0', paddingRight:'0'}}>{format_perc(this.state.data[this.state.edition]["exports"]["increased_logistics_cost"])}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <br></br>
                <p className={styles.graph_source}>Source: CCFGB Survey</p>
            </div>
        );
    }
}

export default EobTable;