import { Grid } from '@material-ui/core';
import React from 'react';
import GraphCard from '../../cards/cardsTemplates/graphCard.js'

// GRAPH TOOLS
import { Tooltip, Legend, AreaChart, Line, Area, XAxis, YAxis, linearGradient } from 'recharts';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// Helper props and functions
import fetchData from '../../functions/fetch_data.js';
import graph_format_numbers from '../../functions/graph_format_numbers.js';

// STYLES
import styles from '../../../styles/main/main_classes.module.css';

const default_region = "EU";

function format_region(region){
    return (region === "ALL") ? "GLOBAL" : region
}

const NetMigrationGraph = (props) => {

    const Graph = (
        <AreaChart data={props.data}>
            <Legend layout="horizontal" verticalAlign="top" align="center" wrapperStyle={{marginTop:'-18px'}}> </Legend>
            <XAxis dataKey="Date" interval={1} tick={{fontSize: 'calc(10px + 0.35vw)'}} padding={{right:15}}/>
            <YAxis 
                tickFormatter={graph_format_numbers}
                tick={{fontSize: 'calc(12px + 0.35vw)'}}
                />
            <Area type="monotone" dataKey="Inflow"  stroke="#118046" strokeWidth={2} fill="#FFFFFF"/>
            <Area type="monotone" dataKey="Outflow"  stroke="#a12b35" strokeWidth={2} fill="#FFFFFF"/>
            <Tooltip
                formatter={graph_format_numbers}
                active={false} />
        </AreaChart>
    )

    return(
        <Grid 
            container>
            <Grid item xs className={styles.graph_containers}>
                <GraphCard
                    title={"EU and non-EU net Migration"}
                    //subtitle={"Brexit introduces new barriers for the flow of people across the Channel. What impact do we expect to see?"}
                    //description={"x"}
                    source={"ONS"}
                    graph={Graph}
                    addons={props.toggle}>
                </GraphCard>
                
            </Grid>
    </Grid>
    )
}

function ToggleFunction(props) {

    return (
        <Autocomplete
            options={props.regions}
            getOptionLabel={(option) => format_region(option)}
            size="small"
            renderInput={(params) => <TextField {...params} label = 'Region' variant="outlined"/>} 
            onChange={props.switch}
            defaultValue={props.value}
            style={{fullWidth:true, width:"150px"}}
        />
    )
}

class NetMigration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: null,
            regions: null,
            region: default_region
        };
    }


    async componentDidMount() {
        const dataset = await fetchData("pi_migration_line");
        this.setState({
            data: dataset,
            isLoading: false,
            regions: Object.keys(dataset)//.filter(item => ((item === 'ALL') || (item === 'EU') || (item === 'NON-EU'))),
        });
    }

    handleSwitchRegion = (event, newValue) => {
        if (newValue !== null) {
            this.setState({ region: newValue });
        }
    }
    
    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }
    
        const toggle = <ToggleFunction 
            value={this.state.region}
            switch={this.handleSwitchRegion}
            regions={this.state.regions}></ToggleFunction>;


        return (
            <div>
                <NetMigrationGraph
                    data={this.state.data[this.state.region]}
                    toggle={toggle}>
                </NetMigrationGraph>
            </div>
        )
    }
}



export default NetMigration;

