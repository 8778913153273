import React from 'react';
import Grid from '@material-ui/core/Grid';

// Components
import CitizenshipGrants from '../charts/customCharts/citizenshipGrants.js'
import NetMigration from '../charts/customCharts/netMigration.js'
import ReasonsImmigration from '../charts/customCharts/reasonsImmigration.js'
import MouseOverPopover from '../buttons/popover.js';

// STYLES
import styles from '../../styles/main/main_classes.module.css';
import format_perc_100base from '../functions/format_perc_100base.js';

export default class ContextSubsection extends React.Component {
  
  render() {
    return (
        <div> 

          <h2 className={styles.titles_with_blue_line}>Context & Background</h2>

          <Grid 
            container
            direction="row"
            style={{ gap : 20 }}>
              <Grid container xs={12} sm
                direction="row"
                style={{textAlign:"justify"}}>
                  <ul>
                      <li style={{marginBottom:"15px"}}>In recent years we have seen immigration from the EU decline, driven largely by reduced inflow from the <MouseOverPopover visibleText="EU8" invisibleText="Czech Republic, Estonia, Hungary, Latvia, Lithuania, Poland, Slovakia & Slovenia"></MouseOverPopover> and <MouseOverPopover visibleText="EU2" invisibleText="Romania & Bulgaria"></MouseOverPopover>.</li>
                      <li style={{marginBottom:"15px"}}>According to the Labour Force Survey, <span style={{fontWeight:"bold"}}> a larger proportion of those coming from the EU ({format_perc_100base(this.props.migratingwork['EU'])}) are coming for work-related reasons</span> compared to those coming from elsewhere ({format_perc_100base(this.props.migratingwork['Non-EU'])}).</li>
                      <li>We can expect this to have significant knock-on effects on the labour supply for British firms.</li>
                  </ul>
                </Grid>
                <Grid xs={12} sm>
                  <NetMigration></NetMigration>
                </Grid>
          </Grid>
          <br></br>
          <Grid 
            container
            direction="column"
            style={{ gap : 20 }}>
              <Grid item xs><CitizenshipGrants></CitizenshipGrants></Grid>
              <Grid item xs><ReasonsImmigration></ReasonsImmigration></Grid>
              
          </Grid>
                      

      
        </div>
    );
  }
};