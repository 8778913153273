import { Grid } from '@material-ui/core';
import React from 'react';
import GraphCard from '../../cards/cardsTemplates/graphCard.js'

// GRAPH TOOLS
import { Tooltip, Legend, LineChart, Line, Area, XAxis, YAxis, linearGradient } from 'recharts';

// Helper props and functions
import fetchData from '../../functions/fetch_data.js';
import graph_format_numbers from '../../functions/graph_format_numbers.js';

// STYLES
import styles from '../../../styles/main/main_classes.module.css';

function generateRandomColor()
    {
        var randomColor = '#'+Math.floor(Math.random(0,0.00001)*16777215).toString(16);
        return randomColor
    }

const country_color = {
    "France":"red",

    "Germany":"#4a4e4d",
    "Ireland":"#1167b1",
    "Italy":"#00008b",
    "Spain":"#03254c",
    "Greece":"#b3cde0",
    "Cyprus (European Union)":"#6497b1",

    "Romania":"#feb2a8",
    "Bulgaria":"#fec8c1",
    "Poland":"#fad9c1",
    "Lithuania":"#f9caa7",
    "Portugal":"#fe9c8f",
}

const StudentCountries = (props) => {

    const countries = props.countries_list

    const Graph = (
        <LineChart data={props.data['data']}>
            <Legend 
                layout="horizontal" 
                iconType="plainline"
                verticalAlign="top" 
                align="center" 
                style={{fontSize: 'calc(8px + 0.35vw)'}}
                wrapperStyle={{marginTop:'-25px', marginBottom:'10px'}}> </Legend>
            <XAxis dataKey="Academic Year" 
                interval={0} 
                padding={{right:30}}
                tick={{fontSize: 'calc(8px + 0.35vw)'}}/>
            <YAxis 
                width={50}
                tick={{fontSize: 'calc(12px + 0.35vw)'}}
                tickFormatter={graph_format_numbers}
                />
            <Tooltip
                formatter={graph_format_numbers}
                active={false} />
                {
                    countries.map((value) => {
                        return(
                            <Line
                                key={value}
                                name={value}
                                dataKey={value}
                                dot={false}
                                stroke={country_color[value]}/>)
                        })
                }
        </LineChart>
    )


    return(
        <Grid 
            container>
            <Grid item xs className={styles.graph_containers}>
                <GraphCard
                    title={"Top 10 EU Sender Countries of Students to the UK"}
                    subtitle={"By Academic Year (from 2014/15)"}
                    source={"HESA"}
                    graph={Graph}>
                </GraphCard>
                
            </Grid>
    </Grid>
    )
}


class StudentSenderCountries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data_graph: null,
            data_date: null,
            countries:null,

        };
    }


    async componentDidMount() {
        const graph_dataset = await fetchData("pi_studentsenders_line");
        this.setState({
            data_graph: graph_dataset,
            isLoading: false,
            data_date: graph_dataset['date'],
            countries:Object.keys(graph_dataset['data'][0]).filter(item => ((item !== 'Academic Year') && (item !== 'Total European Union')))
        });
    }
    
    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }
    
        return (
            <div>
                <StudentCountries
                    data={this.state.data_graph}
                    countries_list={this.state.countries}></StudentCountries>
            </div>
        )
        }
    }

export default StudentSenderCountries;
