import React, { PureComponent }  from 'react';
import TradeBalanceCards from '../cards/trade_balance_cards.js'
// STYLES
export default class TradeBalanceSubsection extends PureComponent {
  
  render() {
    return (
        <div> 
          <TradeBalanceCards></TradeBalanceCards>
          <br></br>
        </div>
    );
  }
}