import React, { PureComponent }  from 'react';
import {ResponsiveContainer} from 'recharts';
import { Grid } from '@material-ui/core';
// import GraphCard from '../../cards/cardsTemplates/graphCard.js'
import GraphCardSmall from '../../cards/cardsTemplates/graphCardSmall.js';
import GraphCardTiny from '../../cards/cardsTemplates/graphCardTiny.js';



// GRAPH TOOLS
import { Tooltip, Legend, BarChart, Bar, Area, XAxis, YAxis, linearGradient } from 'recharts';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// Helper props and functions
import fetchData from '../../functions/fetch_data.js';
import format_perc from '../../functions/format_perc.js';

// STYLES
import styles from '../../../styles/main/main_classes.module.css';

const CustomTooltip = ({ active, payload, label}, props) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{margin:'0px', padding:'5px', backgroundColor:'white', border:'1px solid rgb(204,204,204)', whiteSpace:'nowrap'}}>
          <p className="label" style={{margin:'0px', fontSize:'calc(10px + 0.4vw)'}}><b>{`${label}: `}</b>{format_perc(payload[0].value)}</p>
        </div>
      );
    }
  
    return null;
  };





const DelayDistGraph = (props) => {

    const Graph = (
            <BarChart data={props.data}>
                <XAxis dataKey="name" hide={true}>
                </XAxis>
                <YAxis type="number" domain={[0,0.5]} hide={true}></YAxis>
                <Bar type="monotone" dataKey="value" fill={props.color}/>
                <Tooltip content={<CustomTooltip/>} cursor={<rect fillOpacity="0.3"/>} active={false} wrapperStyle={{zIndex: 1000}}/>
            </BarChart>
    )


    return(
        <Grid container>
            <Grid item xs className={styles.graph_containers_tiny}>
                <GraphCardTiny graph={Graph}></GraphCardTiny>
            </Grid>
        </Grid>
    )
}


class DelayDist extends React.Component {
    render() {
        return (
            <div>       
                <DelayDistGraph data={this.props.data} color={this.props.color}></DelayDistGraph>
            </div>
        )
    }
}

export default DelayDist;