import React from 'react';
import Grid from '@material-ui/core/Grid';

// STYLES
import styles from '../../styles/main/main_classes.module.css';
import colours from '../colours/colours.js';

const Footer = () => {

    return (
        <div style={{background: colours[0], color: "white"}}>
            <Grid 
                container 
                className={styles.footer}
                style={{ gap: 20}}>
                <Grid 
                    item xs = {12} sm
                    direction="column">
                    <h2>CCFGB</h2>
                    <p>Established in 1883, the French Chamber of Great Britain fosters business links and strong economic relations across the Channel. 
                    </p>
                </Grid>
                <Grid 
                    item  xs = {12} sm
                    direction="column"
                    className={styles.footer_container}>
                    <h2>Contact</h2>
                    <p>For more information, contact the <a href="mailto:mail@ccfgb.co.uk" target="_blank" rel="noreferrer" className={styles.link_to_ccfgb}>CCFGB</a>.
                    </p>
                </Grid>
                <Grid 
                    item  xs = {12} sm
                    direction="column"
                    className={styles.footer_container}>
                    <h2>Legal</h2>
                    <p>Ekimetrics publishes these graphs and data in the context of Brexit for information purposes and uses the data and content only for non-commercial purposes.
                    </p>
                </Grid>
            </Grid> 

        </div>
    );
}

export default Footer;
