// Import Components
import NavBar from './components/navbar/navbar.js'
import Footer from './components/footer/footer.js'
import IntroSection from './components/sections/intro.js'
import GoodsAndTradeSection from './components/sections/goods_and_trade.js'
import PeopleAndImmigrationSection from './components/sections/people_and_immigration.js'

// Import CSS
import styles from './styles/main/main_classes.module.css'


function App() {
  return (
    <div>
      <header>
        <div>
          <NavBar />
        </div>
      </header>
      <div container className={styles.gutters}>
        <div 
        className="App"
        style={{padding:'0px 1vw', boxSixing:'border-box', marginRight:'auto', marginLeft:'auto'}}>
          
          <IntroSection></IntroSection>
          
          <GoodsAndTradeSection></GoodsAndTradeSection>
          
          <PeopleAndImmigrationSection></PeopleAndImmigrationSection>
        <br></br>
        </div>
      </div>
      <Footer />
    </div>

  );
}

export default App;
