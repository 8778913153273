import React from 'react';
import Grid from '@material-ui/core/Grid';

// Graph & Card Specific
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import TradeFlowsGraph from '../charts/customCharts/tradeFlows.js'

// Helper props and functions
import KeynbCard from '../cards/cardsTemplates/keynbCard.js';
import fetchData from '../functions/fetch_data.js';
import format_currency from '../functions/format_currency.js';
import format_perc_change from '../functions/format_perc_change.js';

// STYLES
import gt_styles from '../../styles/main/goods_and_trade.module.css';
import styles from '../../styles/main/main_classes.module.css';

const default_industry = "Total";
const default_region = "Global";

function format_industry(industry) {
    return (industry === "Total") ? "All Industries" : industry.slice(2);
}

class TradeBalanceCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data_cards: null,
            data_graph: null,
            industries: null,
            industry: default_industry,
            regions: null,
            region: default_region,
            data_date: null
        };
        
    }

    async componentDidMount() {
        const cards_total_dataset = await fetchData("gt_ons_trade_card");
        const graph_dataset = await fetchData("gt_ons_trade_area");
        this.setState({
            data_cards: cards_total_dataset,
            data_graph: graph_dataset,
            industries: Object.keys(cards_total_dataset).filter(item => (item !== 'date')),
            isLoading: false,
            data_date: cards_total_dataset['date'],
            regions: Object.keys(cards_total_dataset[default_industry]),
        });
    }


    handleSwitchIndustry = (event, newValue) => {
        if (newValue !== null) {
            this.setState({ industry: newValue });
        }
    }

    handleSwitchRegion = (event, newValue) => {
        if (newValue !== null) {
            this.setState({ region:newValue });
        }
    }

    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }

        return (
            <div>
                <Grid 
                    container
                    direction="column">
                    <Grid 
                        item xs>
                            <h2 className={styles.titles_with_blue_line}>Trade Balance</h2>
                    </Grid>
                    <Grid container
                        direction="row"
                        style={{ gap: 20,justifyContent:"space-evenly"}}>
                        <Grid item sm = {12} md ={4}>
                            <ToggleButtonGroup value={this.state.region} exclusive onChange={this.handleSwitchRegion}>
                                {this.state.regions.map((region) =>
                                    <ToggleButton value={region}><div>{region}</div></ToggleButton>
                                )}
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item sm = {12} md={5}>
                            <Autocomplete
                                options={this.state.industries}
                                getOptionLabel={(option) => (option)}
                                size="small"
                                style={{ width: "250px" }}
                                renderInput={(params) => <TextField {...params} label = 'Industries' variant="outlined" fullWidth/>} 
                                onChange={this.handleSwitchIndustry}
                                defaultValue={default_industry}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <br></br>

                <Grid
                    container
                    style={{ gap: 20}}>
                        <Grid container xs
                            direction="row"
                            className={gt_styles.trade_balance_questions}>
                            <ul>
                                <li style={{marginBottom:"15px"}}>
                                    Whilst the EU-UK Trade and Cooperation Agreement maintains a free trade area with no tariffs or quotas, <span style={{fontWeight:"bold"}}> there are substantial, non-tariff barriers imposed </span> and increased room for regulatory divergence.
                                </li>
                                <li>
                                    Figures for {this.state.data_date} give an early indication <span style={{fontWeight:"bold"}}>how these changes may be impacting trade</span> for certain sectors whilst a longer term view highlights the dependency of each industry on the EU.
                                </li>
                            </ul>
                        </Grid>
                        <Grid xs className={gt_styles.trade_balance_balance_card}>
                            <KeynbCard
                                cards_title={"Balance"}
                                key_nb={format_currency(this.state.data_cards[this.state.industry][this.state.region]['gross']['Balance'], 1)}
                                evol={"("+format_perc_change(this.state.data_cards[this.state.industry][this.state.region]['yoy']['Balance'], 1)+" YoY)"}>
                            </KeynbCard>
                        </Grid>
                </Grid>
                <br></br>
                <Grid
                    container
                    style={{ gap: 20}}>
                        <Grid xs className={gt_styles.trade_balance_exports_cards}>
                            <KeynbCard
                                cards_title={"Exports"}
                                key_nb={format_currency(this.state.data_cards[this.state.industry][this.state.region]['gross']['Exports'], 1)}
                                evol={"("+format_perc_change(this.state.data_cards[this.state.industry][this.state.region]['yoy']['Exports'], 1)+" YoY)"}>
                            </KeynbCard>
                        </Grid>
                        <Grid xs className={gt_styles.trade_balance_exports_cards}>
                            <KeynbCard
                                cards_title={"Imports"}
                                key_nb={format_currency(this.state.data_cards[this.state.industry][this.state.region]['gross']['Imports'], 1)}
                                evol={"("+format_perc_change(this.state.data_cards[this.state.industry][this.state.region]['yoy']['Imports'], 1)+" YoY)"}>
                            </KeynbCard>
                        </Grid>
                </Grid>
                <br></br>
                <TradeFlowsGraph
                    data={this.state.data_graph[this.state.industry][this.state.region]}
                    industry={format_industry(this.state.industry)}
                    region={this.state.region}>
                </TradeFlowsGraph>
            </div>

        );
    }
}

export default TradeBalanceCards;
