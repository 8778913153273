import React  from 'react';
import Grid from '@material-ui/core/Grid';

import GoodsTradeIntroBoxes from '../cards/goods_and_trade_intro_boxes.js'
import TradeBalanceSubsection from '../subsection/gt_trade_flows.js'
import FDISubsection from '../subsection/gt_FDI.js'
import EoBSubsection from '../subsection/gt_EoB.js'

// STYLES
import styles from '../../styles/main/main_classes.module.css';
import fetchData from '../functions/fetch_data.js';


export default class GoodsAndTradeSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: null
    }
  }

  async componentDidMount() {
    const dataset = await fetchData("gt_text");
    this.setState({
        data: dataset['data'],
        isLoading: false
    });
  }  


  render() {
    if (this.state.isLoading) {
      return <div>loading...</div>
    } 

    return (
        <div> 

          <br></br>
          <h2 className={styles.titles_with_red_line}>Goods & Trade</h2>
          <h3>The EU is the UK’s largest trading partner. France is the UK’s fourth-largest trading partner in Europe and fifth in the world. Brexit has introduced higher tariff and non-tariff barriers, restricting UK access to the European single market. This section looks at the key indicators on trade in goods.</h3>
          <GoodsTradeIntroBoxes></GoodsTradeIntroBoxes>
          <br></br>
          <TradeBalanceSubsection></TradeBalanceSubsection>
          <br></br>
          <EoBSubsection></EoBSubsection>
          <br></br>
          <FDISubsection></FDISubsection>
          <br></br>
        </div>
    );
  }
}