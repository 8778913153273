import React, { PureComponent }  from 'react';
import Grid from '@material-ui/core/Grid';

// Components
import SkillsvImmigration from '../charts/customCharts/skillvImmigration.js'
import WorkforceEvolution from '../charts/customCharts/workforceEvolution.js'
import EmploymentLevels from '../charts/customCharts/employmentLevels.js'
import MouseOverPopover from '../buttons/popover.js';

// STYLES
import styles from '../../styles/main/main_classes.module.css';
import format_perc_100base from '../functions/format_perc_100base.js';

export default class WorkSubsection extends PureComponent {

  render() {
    return (
        <div> 

          <h2 className={styles.titles_with_blue_line}>Work</h2>
          
          <Grid 
            container
            direction="row"
            style={{ gap : 20 }}>
              <Grid container xs={12} sm
                direction="row"
                style={{textAlign:"justify"}}>
                  <ul>
                  <li style={{marginBottom:"15px"}}>
                        The <span style={{fontWeight:"bold"}}> increased <MouseOverPopover visibleText="cost of visas" invisibleText="£199 per worker in addition to the sponsor license application fee"></MouseOverPopover></span> and the <span style={{fontWeight:"bold"}}>points-based system</span> is a significant barrier for low-skilled migrants. We expect to see difficulties for firms looking to hire
                        workers in some sectors which have <span style={{fontWeight:"bold"}}>traditionally relied on low-skilled, seasonal labour from the EU. </span>
                      </li>                      
                      <li style={{marginBottom:"15px"}}>
                        For firms making new hires, we have seen <span style={{fontWeight:"bold"}}>{format_perc_100base(100*this.props.hires["Decreased"])} report a lower proportion of EU nationals</span> in the last quarter.
                        Meanwhile, just {format_perc_100base(100*this.props.visas["EU"])} of firms have increased the number of visas sponsored for EU nationals compared to just {format_perc_100base(100*this.props.visas["Non-EU"])} for non-EU nationals.
                      </li>
                      <li style={{marginBottom:"15px"}}> 
                        Results from the CCFGB survey show the difficulty with hiring from the EU. Of those firms which quoted a decrease in the proportion of new hires from the EU, <span style={{fontWeight:"bold"}}>{format_perc_100base(100*this.props.reason["fewer_applications"])} cited fewer applications from EU nationals</span> as the main reason, whilst <span style={{fontWeight:"bold"}}>{format_perc_100base(100*this.props.reason["increase_costs"])} pointed towards increased costs associated with recruitment</span>.
                      </li>
                  </ul>
                </Grid>
                <Grid xs={12} sm>
                  <EmploymentLevels></EmploymentLevels>
                </Grid>
          </Grid>
          <br></br>
          <Grid 
            container
            direction="column"
            style={{ gap : 20 }}>
              <Grid item xs><SkillsvImmigration></SkillsvImmigration></Grid>
              <Grid item xs><WorkforceEvolution></WorkforceEvolution></Grid>
              
          </Grid>
             
        </div>
    );
  }
}