import React from 'react';
import Grid from '@material-ui/core/Grid';

// STYLES
import gt_styles from '../../../styles/main/goods_and_trade.module.css';

const KeynbCard = (props) => {

    return (
        <div>
            <Grid item>
                <h2 className={gt_styles.trade_balance_title}>{props.cards_title}</h2>
                <p className={gt_styles.trade_balance_key_nb}>
                    <span>{props.key_nb}</span><br></br>
                    <span className={gt_styles.trade_balance_evol_nb}>{props.evol}</span>
                </p>
            </Grid>
        </div>

    );
}

export default KeynbCard;