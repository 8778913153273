import React, { PureComponent }  from 'react';
import { Grid } from '@material-ui/core';
import GraphCard from '../../cards/cardsTemplates/graphCard.js'

// GRAPH TOOLS
import { Tooltip, Legend, BarChart, Bar, Area, XAxis, YAxis, linearGradient } from 'recharts';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// Helper props and functions
import fetchData from '../../functions/fetch_data.js';
import format_perc from '../../functions/format_perc.js';

// STYLES
import styles from '../../../styles/main/main_classes.module.css';

const default_bucket = "Science";

class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, stroke, payload } = this.props;
  
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} fontSize='calc(6px + 0.3vw)' textAnchor="end" fill="#666" transform="rotate(-45)">
            {payload.value}
          </text>
        </g>
      );
    }
  }

const CoursesChoiceGraph = (props) => {

    const Graph = (
            <BarChart data={props.data}>
                <Legend 
                    layout="horizontal" 
                    verticalAlign="top" 
                    align="center"
                    wrapperStyle={{marginTop:'-12px'}}> </Legend>
                <XAxis 
                    dataKey="Subject Short Name" interval={0}
                    tick={<CustomizedAxisTick/>}
                    minTickGap={0}
                    height={120}/>
                <YAxis 
                    tickFormatter={format_perc}
                    width={60}
                    tick={{fontSize: 'calc(12px + 0.35vw)'}}
                    />
                <Bar type="monotone" dataKey="EU" stackId="a" fill ="#083f80"/>
                <Bar type="monotone" dataKey="Non-EU"  stackId="a" fill = "#86a1c2"/>
                <Bar type="monotone" dataKey="UK" stackId="a" fill="#dadee3"/>
                <Tooltip
                    formatter={format_perc}
                    cursor={<rect fillOpacity="0.3"/>}
                    active={false} />
            </BarChart>
    )

    return(
        <Grid 
            container>
            <Grid item xs className={styles.graph_containers}>
                <GraphCard
                    title={"University Courses Choice by Subject"}
                    subtitle={"Academic Year 2020/21"}
                    source={"HESA"}
                    graph={Graph}
                    addons={props.toggle}>
                </GraphCard>
            </Grid>
    </Grid>
    )
}

function ToggleFunction(props) {

    return (
        <ToggleButtonGroup 
            value={props.value} 
            size="small"
            exclusive onChange={props.switch}>
            {props.buckets.map((bucket) =>
                <ToggleButton value={bucket}><div>{bucket}</div></ToggleButton>
            )}
        </ToggleButtonGroup>
    )

}


class CoursesChoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data_graph: null,
            data_date: null,
            bucket: default_bucket

        };
    }


    async componentDidMount() {
        const graph_dataset = await fetchData("pi_unicourses_bar");
        this.setState({
            data_graph: graph_dataset,
            isLoading: false,
            data_date: graph_dataset['date'],
            education_groups: Object.keys(graph_dataset[default_bucket]),
            education_buckets:Object.keys(graph_dataset).filter(item => (item !== 'date'))
        });
    }
    
    handleSwitchBucket = (event, newValue) => {
        if (newValue !== null) {
            this.setState({ bucket: newValue });
        }
    }


    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }

        const toggle = <ToggleFunction 
            value={this.state.bucket}
            switch={this.handleSwitchBucket}
            buckets={this.state.education_buckets}></ToggleFunction>;

        return (
            <div>       
                <CoursesChoiceGraph
                    data={this.state.data_graph[this.state.bucket]}
                    toggle={toggle} >
                </CoursesChoiceGraph>
            </div>
        )
        }
    }

export default CoursesChoice;