import { Grid } from '@material-ui/core';
import React from 'react';
import GraphCard from '../../cards/cardsTemplates/graphCard.js'

// GRAPH TOOLS
import { Tooltip, Legend, LineChart, Line, Area, XAxis, YAxis, linearGradient } from 'recharts';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// Helper props and functions
import fetchData from '../../functions/fetch_data.js';
import graph_format_numbers from '../../functions/graph_format_numbers.js';

// STYLES
import styles from '../../../styles/main/main_classes.module.css';

const default_region = "EU";

function generateRandomColor()
    {
        var randomColor = '#'+Math.floor(Math.random(0,0.00001)*16777215).toString(16);
        return randomColor
    }

function year_only(value) {
    return (value.substring(0,5))
}


const country_color = {
    
    "France":"red",
    "Germany":"#4a4e4d",
    "Ireland":"#1167b1",
    "Italy":"#00008b",
    "Spain":"#03254c",
    "Greece":"#b3cde0",
    "Cyprus (European Union)":"#6497b1",
    "Romania":"#feb2a8",
    "Bulgaria":"#fec8c1",
    "Poland":"#fad9c1",
    "Lithuania":"#f9caa7",
    "Portugal":"#fe9c8f",

    "Bangladesh":"#4a4e4d",
    "Nigeria":"#1167b1",
    "Pakistan":"#00008b",
    "South Africa":"#03254c",
    "China":"#fec8c1",
    "India":"grey",
    "Philippines":"#feb2a8",
    "Somalia":"#fad9c1",
    "Sri Lanka":"#f9caa7",
    "Zimbabwe":"#fe9c8f"
}

const CitizenshipGrantsGraph = (props) => {

    const Graph = (
        <LineChart data={props.data}>
            <Legend 
                layout="horizontal" 
                iconType="plainline"
                verticalAlign="top" 
                align="center" 
                style={{fontSize: 'calc(10x + 0.35vw)'}}
                wrapperStyle={{marginTop:'-12px', marginBottom:'10px'}}> </Legend>
            <XAxis dataKey="Date" 
                interval={3} 
                padding={{right:20}}
                tick={{fontSize: 'calc(9px + 0.35vw)'}}
                tickFormatter={year_only}/>
            <YAxis 
                width={45}
                tick={{fontSize: 'calc(12px + 0.35vw)'}}
                tickFormatter={graph_format_numbers}
                interval={1}
                />
            <Tooltip
                
                active={false} />
                {
                    props.countries.map((value) => {
                        return(
                            <Line
                                key={value}
                                name={value}
                                dataKey={value}
                                dot={false}
                                stroke={country_color[value]}/>)
                        })
                }
        </LineChart>
    )

    return(
        <Grid 
            container>
            <Grid item xs className={styles.graph_containers}>
                <GraphCard
                    title={"Grants of Citizenship to the Top 10 EU Countries"}
                    source={"ONS"}
                    graph={Graph}
                    addons={props.toggle}>
                </GraphCard>
                
            </Grid>
    </Grid>
    )
}

function ToggleFunction(props) {

    return (
        <ToggleButtonGroup 
            value={props.value} 
            size="small"
            exclusive onChange={props.switch}>
            {props.regions.map((region) =>
                <ToggleButton value={region}><div>{region}</div></ToggleButton>
            )}
        </ToggleButtonGroup>
    )
}




class CitizenshipGrants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: null,
            regions: null,
            region: default_region,
            countries: null,
        };
    }


    async componentDidMount() {
        const dataset = await fetchData("pi_citizenship_line");
        this.setState({
            data: dataset,
            isLoading: false,
            regions: Object.keys(dataset),
            countries: Object.keys(dataset[default_region][0]).filter(item => (item !== 'Date'))
        });
    }

    handleSwitchRegion = (event, newValue) => {
        if (newValue !== null) {
            this.setState({
                region: newValue,
                countries: Object.keys(this.state.data[newValue][0]).filter(item => (item !== 'Date'))
            });
        }
    }
    
    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }
    
        const toggle = <ToggleFunction 
            value={this.state.region}
            switch={this.handleSwitchRegion}
            regions={this.state.regions}></ToggleFunction>;


        return (
            <div>
                <CitizenshipGrantsGraph
                    data={this.state.data[this.state.region]}
                    countries={this.state.countries}
                    toggle={toggle}></CitizenshipGrantsGraph>
            </div>
        )
    }
}

export default CitizenshipGrants;


