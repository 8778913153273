import React from 'react';
import Grid from '@material-ui/core/Grid';

// STYLES
import gt_styles from '../../styles/main/goods_and_trade.module.css';

// Helper functions
import fetchData from '../functions/fetch_data.js';
import format_perc from '../functions/format_perc.js';

class GoodsTradeIntroBoxes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            fx_data: null,
            trade_data: null
        };
    }

    async componentDidMount() {
        const fx_data = await fetchData("gt_fxrate_card");
        const trade_data = await fetchData("gt_tradesplit_card");
        this.setState({
            fx_data: fx_data,
            trade_data: trade_data,
            isLoading: false,
        });
    }

    render() {
        if (this.state.isLoading) {
            return <div>loading...</div>
        }
        
        return (
            <div> 
                <Grid 
                    container
                    justify="center"
                    direction="row"
                    style={{ gap: 20 }}>
                    <Grid
                        item xs={12} sm
                        className={gt_styles.intro_blue_cards}
                        zeroMinWidth>
                        <div>
                            <h2 className={gt_styles.intro_boxes_titles}>GBP/EUR rate</h2>
                            <h3 className={gt_styles.intro_boxes_subtitles}>Current</h3>
                        </div>
                        <p className={gt_styles.intro_boxes_key_number}>{this.state.fx_data['fxrate']}</p>
                    </Grid>
                    <Grid
                        item xs={12} sm
                        className={gt_styles.intro_blue_cards}
                        zeroMinWidth>
                        <div>
                            <h2 className={gt_styles.intro_boxes_titles}>UK Exports to EU as a share of total UK exports</h2>
                            <h3 className={gt_styles.intro_boxes_subtitles}>{this.state.trade_data["date"]}</h3>
                        </div>
                        {/* TODO */}
                        <p className={gt_styles.intro_boxes_key_number}>{format_perc(this.state.trade_data["Exports EU Percentage"]["latest"])}</p>
                    </Grid>
                    <Grid
                        item xs={12} sm
                        className={gt_styles.intro_blue_cards}
                        zeroMinWidth>
                        <div>
                            <h2 className={gt_styles.intro_boxes_titles}>UK Imports from EU as a share of total UK imports</h2>
                            <h3 className={gt_styles.intro_boxes_subtitles}>{this.state.trade_data["date"]}</h3>
                        </div>
                        {/* TODO */}
                        <p 
                            className={gt_styles.intro_boxes_key_number}>{format_perc(this.state.trade_data["Imports EU Percentage"]["latest"])}</p>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default GoodsTradeIntroBoxes;
